import React, { useRef } from "react"
import dynamic from "next/dynamic"
import PropTypes from "prop-types"
import { useRouter } from "next/router"
import { useForm } from "react-hook-form"
import { scroller } from "react-scroll"

import { useGlobalContext } from "../../context/layout-context"

const ZipCodeCity = dynamic(() => import("../inputs/zip-code-city"), {
  ssr: false,
})

function FindANandos({ label, name, placeholder, displayLabel, setAreErrors }) {
  const router = useRouter()
  const { globalState, updateGlobalState } = useGlobalContext()
  const { headerHeight } = globalState || {}
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const searchInput = useRef(null)

  const clearSearchBox = () => {
    searchInput.current.value = ""
  }

  const onSubmit = data => {
    updateGlobalState({
      locationToSearch: data.zip_city,
    })
    // if user is already on /find page, then we don't need do a redirect
    if (router.pathname !== "/find" || router.asPath !== "/find") {
      router.push("/find")
      setTimeout(() => {
        scroller.scrollTo("locations-container", {
          duration: 300,
          delay: 0,
          smooth: "easeInOutQuart",
          offset: -headerHeight,
        })
      }, 300)
    }
    if (
      router.pathname === "/find" ||
      router.asPath === "/find" ||
      router.pathname.includes("/find") ||
      router.asPath.includes("/find")
    ) {
      setTimeout(() => {
        scroller.scrollTo("locations-container", {
          duration: 300,
          delay: 0,
          smooth: "easeInOutQuart",
          offset: -headerHeight,
        })
      }, 300)
    }
  }

  if (errors.zip_city) {
    setAreErrors(true)
  } else {
    setAreErrors(false)
  }

  return (
    <React.StrictMode>
      {displayLabel ? (
        <form
          onSubmit={handleSubmit(onSubmit)}
          data-layer-identifier={name}
          data-layer-name={name}
        >
          <label
            htmlFor={name}
            className="font-noto text-sm cursor-pointer mb-0 text-white flex items-center flex-wrap w-full relative"
          >
            <p className="w-full font-noto-bold text-sm mb-2">{label}</p>
            <div className="flex items-stretch w-full">
              <ZipCodeCity
                register={register}
                errors={errors}
                name="zip_city"
                id="find_input"
                defaultValue="zip_city"
                placeholder={placeholder}
                onFocus={clearSearchBox}
                classList="search-input"
              />
              <button
                className="btn btn-yellow text-black leading-none text-xs px-4 pb-1.5 pt-2.5 flex items-center"
                type="submit"
              >
                Search
              </button>
            </div>
            {errors && errors.zip_city && (
              <p className="pl-0 mb-0 mt-2 text-xs text-black absolute -bottom-8 left-0 font-noto-bold">
                {errors.zip_city.message}
              </p>
            )}
          </label>
        </form>
      ) : null}
    </React.StrictMode>
  )
}

FindANandos.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  displayLabel: PropTypes.bool,
}

FindANandos.defaultProps = {
  name: "",
  label: "",
  placeholder: "",
  displayLabel: false,
}

export default FindANandos
